'use client'

import useBlotout from 'components/hooks/use-blotout';
import useCustomAnalytics from 'components/hooks/use-custom-analytics';
import useGtm from 'components/hooks/use-gtm';
import { FunnelData } from 'shared-react-components';

export function PageEvents({ funnelData }: { funnelData?: FunnelData }) {
  useGtm({ funnelData });
  useCustomAnalytics({ funnelData });
  useBlotout({ funnelData });

  return null
}